<template>
    <div style="margin-top: 10%">
        <img style="width: 200px;" class="d-block m-auto" src="../assets/logo.png" alt="">
        <p class="text-center mt-2 text-sm text-muted">Nền tảng kết nối đến nhà tuyển dụng dành cho Ứng viên</p>
        <div class="text-center mt-4 font-weight-light">
            <div v-if="isLoading">
                <loading></loading>
            </div>
            <!-- <div v-else>
                <router-link v-if="isLogined" :to="{name: 'app.conversations'}" class="btn-rounded btn btn-sm btn-success">Tiếp tục dưới tên <span class="font-weight-bold">{{ user.fullname }}</span> </router-link>
                <a class="btn-rounded btn btn-sm btn-success" v-else :href="authRedirect">Bấm vào đây để tiếp tục</a>
            </div> -->
        </div>
    </div>
</template>
<style scoped>
    .btn-success {
        background: #6fbe44;
        border-radius: 25px;
        border: 1px solid #6fbe44;
        padding: 7px 15px;
    }
    .text-sm {
        font-size: 14px;
    }
</style>
<script>
import cookie from '@/helpers/cookie';
import { mapState } from 'vuex';
import Loading from '@/components/Loading';
export default {
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        Loading
    },
    computed: {
        ...mapState('auth', {
            user: state => state.user,
            isLogined: state => state.isLogined
        }),
        authRedirect() {
            let rootUrl = window.location.href;
            return `${process.env.VUE_APP_CHECK_AUTH_URL}?redirect_to=${rootUrl}`;
        }
    },
    async created() {
        if (cookie.has('sso_token')) {
            await this.authenticate();
        }
        window.location.href = this.authRedirect;
    },
    methods: {
        async authenticate() {
            this.isLoading = true;
            await this.$store.dispatch('auth/authenticate');
            this.isLoading = false;
        },
    }
}
</script>